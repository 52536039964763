import { CommonService } from './../../services/common.service';
import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit, AfterViewInit {
  @Input() modal: boolean = false;
  year: number = new Date().getFullYear();
  counter: number = 0;

  constructor(
    private commonService: CommonService,
    private modalService: NgbModal) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.commonService.getWebCounter()
      .subscribe((response: number) => {
        if (response) {
          this.counter = response;
        }
      });
  }

  close() {
    this.modalService.dismissAll('Cross click');
  }

  openBtn() {

  }
}
