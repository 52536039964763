import { TokenInterceptor } from './_auth/token.interceptor';
import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SharedModule } from './shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export function tokenGetter() {
  return (typeof localStorage != "undefined") ? localStorage.getItem("token") : null;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ["localhost:4200","localhost:5000", "localhost:4000", "localhost", "10.1.94.141:9595", "10.1.94.111:9697", "10.1.94.111:9696", "10.1.94.111:5000", "10.1.94.111", "indextb-test.orpgujarat.com", "indextb.com"],
        disallowedRoutes: [],
      }
    }),
    SharedModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
  ],

  // providers: [
  //   {
  //  provide: 'API_BASE_URL', useValue: 'https://indextb.com/'    
  //   }
  // ],
  bootstrap: [AppComponent]
})
export class AppModule { }
