import { NotFoundComponent } from './shared/components/not-found/not-found.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { NoAccessComponent } from './shared/components/no-access/no-access.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'page',
    data: { breadcrumb: 'Page' },
    loadChildren: () => import('./page/page.module').then(m => m.PageModule)
  },
  //273 {
  //   path: 'news',
  //   data: { breadcrumb: 'News' },
  //   loadChildren: () => import('./news/news.module').then(m => m.NewsModule)
  //  },
  {
    path: 'notice-board',
    data: { breadcrumb: 'Notice Board' },
    loadChildren: () => import('./notice-board/notice-board.module').then(m => m.NoticeBoardModule)
  },
  {
    path: 'faq',
    data: { breadcrumb: 'FAQ' },
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'policy',
    data: { breadcrumb: 'Policy' },
    loadChildren: () => import('./policy/policy.module').then(m => m.PolicyModule)
  },
  {
    path: 'history',
    data: { breadcrumb: 'History' },
    loadChildren: () => import('./history/history.module').then(m => m.HistoryModule)
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    data: { breadcrumb: 'Search' }
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
    data: { breadcrumb: 'Contact' }
  },
  {
    path: 'inquiry',
    loadChildren: () => import('./inquiry/inquiry.module').then(m => m.InquiryModule),
    data: { breadcrumb: 'Inquiry' }
  },
  {
    path: 'sector',
    data: { breadcrumb: 'Sector' },
    loadChildren: () => import('./sector/sector.module').then(m => m.SectorModule)
  },
  {
    path: 'success-story',
    data: { breadcrumb: 'Success Story' },
    loadChildren: () => import('./success-story/success-story.module').then(m => m.SuccessStoryModule),
  },
  {
    path: 'country-desk',
    data: { breadcrumb: 'Country Desk' },
    loadChildren: () => import('./country-desk/country-desk.module').then(m => m.CountryDeskModule)
  },
  {
    path: 'account',
    data: { breadcrumb: 'Account' },
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'profile',
    data: { breadcrumb: 'Profile' },
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'admin',
    data: { breadcrumb: 'Admin' },
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'home',
    data: { breadcrumb: 'Home' },
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'event-registration',
    data: { breadcrumb: 'Event Registration' },
    loadChildren: () => import('./event-registration/event-registration.module').then(m => m.EventRegistrationModule)
  },
  {
    path: 'industrial-policy',
    data: { breadcrumb: 'IndustrialPolicy' },
    loadChildren: () => import('./industrial-policy/industrial-policy.module').then(m => m.IndustrialPolicyModule)
  },
  {
    path: 'no-access',
    component: NoAccessComponent,
    data: { breadcrumb: "No Access" }
  },
  { path: '404', component: NotFoundComponent },
  { path: '**', redirectTo: '/404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
