import { Injectable, Inject } from '@angular/core';
import {DataService} from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class FAQService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {
    super(baseUrl + 'api/FAQs', httpClient);
  }

  getFAQs() {
    return this.httpClient.get(this.baseUrl + 'api/FAQs/all')
    .pipe(catchError(this.handleError))
  }

}