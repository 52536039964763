import { DomSanitizer } from '@angular/platform-browser';
import { ISuccessStory } from '../../../_models/news/ISuccessStory';
import { SuccessStoryService } from '../../services/success-story.service';
import { IPaged } from '../../../_models/common/IPaged';
import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-success-story',
  templateUrl: './success-story.component.html'
})
export class SuccessStoryComponent implements OnInit {
  slideOneConfig = { "lazyLoad": 'ondemand', "slidesToShow": 2, "slidesToScroll": 1, "arrows": true, "dots": false, "autoplay": true, "autoplaySpeed": 2000 };
  @Input() id: number;
  private readonly PAGE_SIZE = 10;
  videos: ISuccessStory[];
  stories: ISuccessStory[];

  queryResult: IPaged = {
    pageNumber: 0,
    items: [],
    totalRows: 0,
    pageSize: this.PAGE_SIZE
  };

  query: any = {
    page: 1,
    pageSize: this.PAGE_SIZE,
    sort: 'Id',
    sortDir: 'Descending',
    sectorId: ''
  };

  successStory: any;
  constructor(
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private service: SuccessStoryService) {

  }

  ngOnInit() {
    this.populateSuccessStories();
  }

  private populateSuccessStories() {
    this.query.sectorId = this.id;
    this.service.getAll(this.query)
      .subscribe((response: IPaged) => {
        if (response) {
          this.queryResult = response;
          if (this.queryResult.items)
            this.stories = this.queryResult.items.filter(x => x.url == null);
          if (this.queryResult.items)
            this.videos = this.queryResult.items.filter(x => x.url != null);
        }
      });
  }

  youtube_parser(url: string) {
    var stepTwo = url.split('/');
    var videoId = stepTwo[stepTwo.length - 1];
    return videoId;
  }

  open(content1, content2, item) {
    this.successStory = item;
    if (this.successStory.url) {
      this.modalService.open(content2, { size: 'xl' });
    }
    else {
      this.modalService.open(content1, { size: 'xl' });
    }

  }

}
