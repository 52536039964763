import { IVideoGallery } from '../../../_models/cms/IVideoGallery';
import { VideoGalleryService } from '../../services/video-gallery.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-video-slider',
  templateUrl: './video-slider.component.html',
  styleUrls: ['./video-slider.component.css']
})
export class VideoSliderComponent implements OnInit {

  slideOneConfig = { "lazyLoad": 'ondemand', "slidesToShow": 1, "asNavFor": '.slider-nav-vs', "slidesToScroll": 1, "arrows": false, "dots": false, "autoplay": true, "autoplaySpeed": 3000 };
  slideTwoConfig = { "lazyLoad": 'ondemand', "slidesToShow": 3, "asNavFor": '.slider-for-vs', "slidesToScroll": 1, "arrows": true, "dots": false, "autoplay": true, "autoplaySpeed": 3000 };
  slideConfig = { "lazyLoad": 'ondemand', "slidesToShow": 1, "slidesToScroll": 1, "arrows": true, "dots": false, "autoplay": true, "autoplaySpeed": 2000 };

  videoGalleries: IVideoGallery[];
  gallery: IVideoGallery;
  url: string;

  constructor(
    private service: VideoGalleryService,
    private modalService: NgbModal) { }


  ngOnInit(): void {
    this.getVideoGallery();
  }

  getVideoGallery() {
    this.service.getFeatured().subscribe((response: IVideoGallery[]) => {
      if (response) {
        this.videoGalleries = response;
      }
    })
  }

  youtube_parser(url: string) {
    var stepTwo = url.split('/');
    var videoId = stepTwo[stepTwo.length - 1];
    return videoId;
  }

  open(content, item) {
    this.gallery = item;
    this.modalService.open(content, { size: 'xl' });
  }

}
