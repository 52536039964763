import { CommonService } from './../../../shared/services/common.service';
import { IKeyValuePair } from './../../../_models/common/common';
import { DialogComponent } from './../../../shared/components/dialog/dialog.component';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { HeaderService } from './../../../shared/services/header.service';
import { ToastrService } from './../../../shared/services/toastr.service';
import { IEventRegister } from './../../../_models/form/IEventRegister';
import { EventRegisterService } from './../../../shared/services/event-register.service';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  loading: boolean;
  title: string = 'Webinar on : Destination Gujarat - Strategies and Opportunities';
  eventRegister: IEventRegister;
  eventRegisterForm: FormGroup;
  is_navbar_ghost: boolean | null;

  associations: IKeyValuePair[];

  constructor(private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private service: EventRegisterService) { 
      this.router.navigate(['/404']);
    }

  ngOnInit() {
    //this.bindDropDown();
    this.createEventRegisterForm();
    //this.setAssociationValidators();
  }

  createEventRegisterForm() {
    this.eventRegisterForm = this.fb.group({
      id: ['0', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      mobileNo: ['', [Validators.required, Validators.pattern(/^[0][6-9]\d{9}$|^[6-9]\d{9}$/)]],
      email: ['', [Validators.required, Validators.email]],
      country: ['', Validators.required],
      state: ['', Validators.required],
      city: ['', Validators.required],
      designation: [''],
      company: ['']
    });
  }

  // bindDropDown() {
  //   this.commonService.getAssociations().subscribe((response: IKeyValuePair[]) => {
  //     if (response) {
  //       this.associations = response;
  //     }
  //   });
  // }

  // setAssociationValidators() {
  //   const otherAssociationControl = this.eventRegisterForm.get('otherAssociation');

  //   this.eventRegisterForm.get('associationId').valueChanges
  //     .subscribe(associationId => {

  //       if (associationId == '4') {
  //         otherAssociationControl.setValidators([Validators.required]);
  //       }
  //       else {
  //         otherAssociationControl.setValidators(null);
  //       }

  //       otherAssociationControl.updateValueAndValidity();
  //     });
  // }

  save() {
    if (this.eventRegisterForm.valid) {
      this.loading = true;
      this.eventRegister = Object.assign({}, this.eventRegisterForm.value);
      this.service.create(this.eventRegister).subscribe(() => {
        this.success();
        setTimeout(() => {
          this.modalService.dismissAll();
          this.router.navigate(['/']);
        }, 5000);
        this.eventRegisterForm.reset();
      },
        (error) => console.log('HTTP Error', error),
        () => this.loading = false);
    }
  }

  success() {
    const modalRef = this.modalService.open(DialogComponent, { centered: true });
    modalRef.componentInstance.message = '<p>Your Event Registration has been submitted successfully. We will get in touch with you soon.</p>';
  }
}
