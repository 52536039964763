import { IndPolicyService } from './services/ind-policy.service';
import { EventRegisterService } from './services/event-register.service';
import { SuccessStoryCategoryService } from './services/success-story-category.service';
import { VideoGalleryItemsService } from './services/video-gallery-items.service';
import { NgModule, ErrorHandler, LOCALE_ID } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter, NgbTimeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgsRevealModule } from 'ngx-scrollreveal';
import { FlipModule } from 'ngx-flip';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';

import { AppErrorHandler } from './_common/app-error-handler';
import { DataService } from './services/data.service';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './services/auth-guard.service';
import { AdminAuthGuard } from './services/admin-auth-guard.service';
import { ToastrService } from './services/toastr.service';
import { HeaderService } from './services/header.service';

import { ApplicationStateService } from './services/application-state.service';
import { CommonService } from './services/common.service';
import { MediaFileService } from './services/media-file.service';
import { MenuService } from './services/menu.service';
import { MenuContentService } from './services/menu-content.service';
import { CmsPageService } from './services/cms-page.service';
import { EditorConfigService } from './services/angular-editor.service';
import { FAQCategoryService } from './services/faq-category.service';
import { FAQService } from './services/faq.service';
import { NoticeBoardService } from './services/notice-board.service';
import { NoticeBoardCategoryService } from './services/notice-board-category.service';
import { FeedbackService } from './services/feedback.service';
import { PolicyService } from './services/policy.service';
import { NewsItemService } from './services/news-item.service';
import { InquiryService } from './services/inquiry.service';
import { SectorService } from './services/sector.service';
import { CountryDeskService } from './services/country-desk.service';
import { VideoGalleryService } from './services/video-gallery.service';
import { SuccessStoryService } from './services/success-story.service';
import { TeamService } from './services/team.service';

import { PaginationComponent } from './components/pagination/pagination.component';
import { MyInputDirective } from './_directives/my-input.directive';
import { TruncatePipe } from './pipes/truncate.pipe';
import { NoAccessComponent } from './components/no-access/no-access.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MyErrorComponent } from './components/my-error/my-error.component';
import { LoaderComponent } from './components/loader/loader.component';
import { UserBarComponent } from './components/user-bar/user-bar.component';
import { TwitterComponent } from './components/twitter/twitter.component';
import { VideoSliderComponent } from './components/video-slider/video-slider.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { FooterComponent } from './components/footer/footer.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MenuItemComponent } from './components/menu-item/menu-item.component';
import { SidebarLinksComponent } from './components/sidebar-links/sidebar-links.component';
import { SectorPolicyComponent } from './components/sector-policy/sector-policy.component';
import { SuccessStoryComponent } from './components/success-story/success-story.component';
import { CustomDateAdapter, CustomDateParserFormatter } from './_common/custom-date-formatter';
import { CustomTimeAdapter } from './_common/custom-time-formatter';
import { ParallaxDirective } from './_directives/parallax.directive';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { NgPipesModule } from 'ngx-pipes';
import { MobileOnlyDirective } from './_directives/mobile-only.directive';
import { DialogComponent } from './components/dialog/dialog.component';
import { StickyNotificationComponent } from './components/sticky-notification/sticky-notification.component';
//import { LazyImgDirective } from './_directives/lazy-img.directive';
import { VideoGalleryComponent } from './components/video-gallery/video-gallery.component';

@NgModule({
  declarations: [
    PaginationComponent,
    MyInputDirective,
    NoAccessComponent,
    NotFoundComponent,
    MyErrorComponent,
    LoaderComponent,
    UserBarComponent,
    NavBarComponent,
    FooterComponent,
    TwitterComponent,
    VideoSliderComponent,
    MenuItemComponent,
    SidebarLinksComponent,
    SectorPolicyComponent,
    SuccessStoryComponent,
    TruncatePipe,
    ParallaxDirective,
    SafeUrlPipe,
    MobileOnlyDirective,
    DialogComponent,
    StickyNotificationComponent,
    //LazyImgDirective,
    VideoGalleryComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    AngularEditorModule,
    SlickCarouselModule,
    NgScrollbarModule,
    InlineSVGModule,
    NgsRevealModule,
    FlipModule,
    NgPipesModule,
    
    ScrollToModule.forRoot()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgbModule,
    AngularEditorModule,
    SlickCarouselModule,
    NgScrollbarModule,
    InlineSVGModule,
    NgsRevealModule,
    FlipModule,
    NgPipesModule,
    ScrollToModule,
    MobileOnlyDirective,
    DialogComponent,

    TruncatePipe,
    SafeUrlPipe,
    PaginationComponent,
    MyInputDirective,
    //LazyImgDirective,
    ParallaxDirective,
    NoAccessComponent,
    NotFoundComponent,
    MyErrorComponent,
    LoaderComponent,
    UserBarComponent,
    FooterComponent,
    TwitterComponent,
    VideoSliderComponent,
    NavBarComponent,
    SidebarLinksComponent,
    SectorPolicyComponent,
    SuccessStoryComponent,
    StickyNotificationComponent,
    VideoGalleryComponent
  ],
  providers: [
    DatePipe,
    DataService,
    { provide: 'API_BASE_URL', useValue: '/' },
    AuthService,
    AuthGuard,
    AdminAuthGuard,
    ToastrService,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: LOCALE_ID, useValue: 'en-IN' },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    { provide: NgbTimeAdapter, useClass: CustomTimeAdapter },
    ApplicationStateService,
    CommonService,
    EditorConfigService,
    HeaderService,
    MediaFileService,
    CmsPageService,
    MenuContentService,
    MenuService,
    NoticeBoardCategoryService,
    NoticeBoardService,
    FAQCategoryService,
    FAQService,
    FeedbackService,
    NewsItemService,
    PolicyService,
    InquiryService,
    CountryDeskService,
    SectorService,
    VideoGalleryService,
    VideoGalleryItemsService,
    SuccessStoryCategoryService,
    SuccessStoryService,
    TeamService,
    EventRegisterService,
    IndPolicyService
  ]
})
export class SharedModule { }
