import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private hideGhost = new BehaviorSubject<boolean | null>(true);
  hideGhostChanges$ = this.hideGhost.asObservable();

  private title = new BehaviorSubject<string | null>("Invest in Gujarat | State Investment Promotion and Facilitation Agency");
  titleChanges$ = this.title.asObservable();

  changehideGhost(hideGhost: boolean | null): void {
    this.hideGhost.next(hideGhost);
  }

  changeTitle(title: string | null, description?: string | null, url?: string | null): void {
    if (title) {
      this.titleService.setTitle(title + " - iNDEXTb");
    }
    else {
      this.titleService.setTitle("Invest in Gujarat | State Investment Promotion and Facilitation Agency");
    }
    this.metaTagService.updateTag(
      { name: 'description', content: description ? description : "Industrial Extension Bureau - iNDEXTb is Government of Gujarat Investment Promotion and Facilitation Agency that helps investors looking for investment in Gujarat." }
    );

    if (url) {
      const head = this.dom.getElementsByTagName('head')[0];
      var element: HTMLLinkElement = this.dom.querySelector(`link[rel='canonical']`) || null
      if (element == null) {
        element = this.dom.createElement('link') as HTMLLinkElement;
        head.appendChild(element);
      }
      element.setAttribute('rel', 'canonical')
      element.setAttribute('href', "https://indextb.com" + url)
    }
  }

  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    @Inject(DOCUMENT) private dom) {

  }
}
