import { PolicyService } from '../../services/policy.service';
import { IPaged } from '../../../_models/common/IPaged';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sector-policy',
  templateUrl: './sector-policy.component.html',
  styleUrls: ['./sector-policy.component.css']
})
export class SectorPolicyComponent implements OnInit {
  @Input() id: number;
  private readonly PAGE_SIZE = 10;

  queryResult: IPaged = {
    pageNumber: 0,
    items: [],
    totalRows: 0,
    pageSize: this.PAGE_SIZE
  };

  query: any = {
    page: 1,
    pageSize: this.PAGE_SIZE,
    sort: 'Id',
    sortDir: 'Descending',
    sectorId: ''
  };

  constructor(
    private service: PolicyService
  ) { }

  ngOnInit(): void {
    this.populatePolicies();
  }


  private populatePolicies() {
    this.query.sectorId = this.id;
    this.service.getAll(this.query)
      .subscribe((response: IPaged) => {
        if (response) {
          this.queryResult = response;
        }
      });
  }

}
