import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CountryDeskService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {
    super(baseUrl + 'api/country-desks', httpClient);
  }

  getBySlug(slug: string) {
    return this.httpClient.get(this.baseUrl + 'api/country-desks/by-slug?slug=' + slug)
      .pipe(catchError(this.handleError))
  }

}