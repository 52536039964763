import { Injectable, Inject } from '@angular/core';
import {DataService} from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class MenuService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {
    super(baseUrl + 'api/Menus', httpClient);
  }
  
  getAllMenus() {
    return this.httpClient.get(this.baseUrl + 'api/Menus/all')
    .pipe(catchError(this.handleError))
  }

}