import { CommonService } from './../../services/common.service';
import { IMenuContent, IMenu } from '../../../_models/cms/IMenu';
import { MenuService } from './../../services/menu.service';
import { AuthService } from './../../services/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './../../../event-registration/components/dashboard/dashboard.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {
  @Input() class: string = 'shadow-sm navbar-light bg-light navbar-new sticky-top';
  @Input() logo: string = '/assets/img/brand/logo-white.svg';
  ishideElection: boolean = true;
  alertClose: boolean = true;

  date: Date = new Date();
  isCollapsed = true;

  primaryMenuContents: IMenuContent[];

  count: number;

  sector: Array<{ title: string, url: string }> = [
    { "title": "Suggestive Project Outline", "url": "/sector/project-outlines" },
    { "title": "Aerospace & Defence", "url": "/sector/aerospace-defence" },
    { "title": "Agro & Food Processing", "url": "/sector/agro-food-processing" },
    { "title": "Automobile & Auto Component", "url": "/sector/automobile-component" },//31Aug
    { "title": "Chemicals & Petrochemicals", "url": "/sector/chemical" },
    { "title": "ESDM", "url": "/sector/esdm" },
    { "title": "IT & ITES", "url": "/sector/it-ites" },
    { "title": "MSMEs", "url": "/sector/msmes" },
    { "title": "Pharmaceuticals & Medical Devices", "url": "/sector/pharmaceutical-medical" },
    { "title": "Renewable Energy", "url": "/sector/renewable-energy" },//31Aug
    { "title": "Textiles", "url": "/sector/textiles" }

  ]

  countryList: Array<{ title: string, url: string }> = [
    { "title": "Australia", "url": "/country-desk/Australia" },
    { "title": "Canada", "url": "/country-desk/Canada" },
    { "title": "Czech Republic", "url": "/country-desk/czech-republic" },
    { "title": "Denmark", "url": "/country-desk/Denmark" },
    { "title": "Finland", "url": "/country-desk/Finland" },
    { "title": "France", "url": "/country-desk/france" },
    { "title": "Germany", "url": "/country-desk/Germany" },
    { "title": "Italy", "url": "/country-desk/Italy" },
    { "title": "Japan", "url": "/country-desk/japan" },
    { "title": "Malaysia", "url": "/country-desk/Malaysia" },
    { "title": "Netherlands", "url": "/country-desk/Netherlands" },
    { "title": "Norway", "url": "/country-desk/norway" },
    { "title": "Oman", "url": "/country-desk/oman" },
    { "title": "Poland", "url": "/country-desk/Poland" },
    { "title": "Portugal", "url": "/country-desk/Portugal" },
    { "title": "Russia", "url": "/country-desk/Russia" },
    { "title": "Saudi Arabia", "url": "/country-desk/saudi-arabia" },
    { "title": "Singapore", "url": "/country-desk/Singapore" },
    { "title": "South Africa", "url": "/country-desk/south-africa" },
    { "title": "South Korea", "url": "/country-desk/south-korea" },
    { "title": "Spain", "url": "/country-desk/Spain" },
    { "title": "Sweden", "url": "/country-desk/sweden" },
    { "title": "Taiwan", "url": "/country-desk/taiwan" },
    { "title": "Thailand", "url": "/country-desk/Thailand" },
    { "title": "Turkey", "url": "/country-desk/Turkey" },
    { "title": "UAE", "url": "/country-desk/uae" },
    { "title": "UK", "url": "/country-desk/UK" },
    { "title": "USA", "url": "/country-desk/USA" },
    { "title": "Uzbekistan", "url": "/country-desk/Uzbekistan" }
  ]

  constructor(
    private authService: AuthService,
    private menuService: MenuService,
    private commonService: CommonService,
    private modalService: NgbModal) {
  }

  ngOnInit() {
    this.loadMenu();
  }

  loadMenu() {
    this.menuService.get(1).subscribe((response: IMenu) => {
      if (response) {
        this.primaryMenuContents = response.menuContents.filter(x => x.parentMenuContentId == null)
          .sort(this.GetSortOrder("order"));
      }
    });

    this.commonService.getLatestCount().subscribe((response: number) => {
      if (response) this.count = response;
    });
  }

  get isLoggedIn() {
    return this.authService.isLoggedIn();
  }

  GetSortOrder(prop) {
    return (a, b) => {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
      return 0;
    }
  }

  open() {
    const modalRef = this.modalService.open(DashboardComponent, { size: 'md', centered: true });
  }

  close() {
    this.alertClose = false;
  }
}
