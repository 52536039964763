import { Injectable, Inject } from '@angular/core';
import { DataService } from "./data.service";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

@Injectable()
export class CommonService extends DataService {

  constructor(httpClient: HttpClient, @Inject('API_BASE_URL') private baseUrl: string) {
    super(baseUrl + 'api/common', httpClient);
  }

  getWebCounter() {
    return this.httpClient.get(this.baseUrl + 'api/Common/web-counter')
      .pipe(catchError(this.handleError))
  }

  getCountries() {
    return this.httpClient.get(this.baseUrl + 'api/common/countries')
      .pipe(catchError(this.handleError))
  }

  getStates(countryId: number) {
    return this.httpClient.get(this.baseUrl + 'api/common/state-territories/' + countryId)
      .pipe(catchError(this.handleError))
  }

  getCityMasters(stateTerritoryId) {
    return this.httpClient.get(this.baseUrl + 'api/common/city-masters/' + stateTerritoryId)
      .pipe(catchError(this.handleError))
  }

  getSectors() {
    return this.httpClient.get(this.baseUrl + 'api/common/sectors')
      .pipe(catchError(this.handleError))
  }

  getCountryDesks() {
    return this.httpClient.get(this.baseUrl + 'api/common/country-desks')
      .pipe(catchError(this.handleError))
  }


  getLatestCount() {
    return this.httpClient.get(this.baseUrl + 'api/common/latest-count')
      .pipe(catchError(this.handleError))
  }

  getAssociations() {
    return this.httpClient.get(this.baseUrl + 'api/common/associations')
      .pipe(catchError(this.handleError))
  }

  getNoticeBoardArchives() {
    return this.httpClient.get(this.baseUrl + 'api/common/notice-board-archives')
      .pipe(catchError(this.handleError))
  }

  getNewsArchives() {
    return this.httpClient.get(this.baseUrl + 'api/common/news-archives')
      .pipe(catchError(this.handleError))
  }

  getGalleryCategory() {
    return this.httpClient.get(this.baseUrl + 'api/common/gallery-categories')
      .pipe(catchError(this.handleError))
  }

  getIndPolicyCategory() {
    return this.httpClient.get(this.baseUrl + 'api/common/indpolicy-categories')
      .pipe(catchError(this.handleError))
  }

  getLanguages() {
    return this.httpClient.get(this.baseUrl + 'api/common/languages')
      .pipe(catchError(this.handleError))
  }
}