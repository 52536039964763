import {ErrorHandler, Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class AppErrorHandler implements ErrorHandler {

  constructor(){}

  handleError(error: any): void {
    console.log('An unexpected error occurred!.');
    console.log(error);
  }

}